import { ref } from '@vue/composition-api'

export default function merchan() {
  const userForm = ref({
    merchant_id: '',
    name: '',
    is_active: '',
    client_cashback: '',
    zerocash_cashback: '',
    zerocash_cashback_type: '',
    client_cashback_type: '',
  })

  const viewData = ref({})
  const resolveActiveUser = role => {
    if (role === true) return 'Active'

    return 'Not Active'
  }

  return {
    userForm,
    viewData,
    resolveActiveUser,
  }
}
