<template>
  <div>
    <div v-if="notFoundBranch">
      <b-card class="text-center">
        <feather-icon
          size="80"
          icon="SearchIcon"
        />
        <div class="error mt-2">
          <h2 class="my-2">
            Branch not found
          </h2>
          <b-button
            variant="outline-primary"
            @click="() => $router.push({name: 'branches'})"
          >
            Back to branches
          </b-button>
        </div>
      </b-card>
    </div>
    <div v-else>
      <div
        v-if="loader"
        class="text-center mt-5"
      >
        <b-spinner
          type="grow"
          label="Loading..."
        />
      </div>
      <b-row v-else>
        <b-col
          cols="12"
          md="4"
        >
          <b-card>
            <div class="contact-box center-version">
              <div class="top">
                <h3 class="m-b-xs">
                  <strong> {{ viewData.name }} </strong>
                </h3>
                <div class="font-bold">
                  @{{ viewData.merchant_name }}
                </div>
              </div>
              <div class="user-info">
                <ul>
                  <li>
                    <feather-icon
                      icon="ActivityIcon"
                    /> {{ resolveActiveUser(viewData.is_active) }}
                  </li>
                </ul>
              </div>
              <div class="contact-box-footer">
                <h5>Other Details</h5>
                <div class="m-t-xs btn-group">
                  <ul>
                    <li> <strong>Client Cashback: </strong>{{ viewData.client_cashback }} </li>
                    <li> <strong>Zerocash Cashback: </strong>{{ viewData.zerocash_cashback }} </li>
                  </ul>
                </div>
              </div>
            </div>
          </b-card>
          <!-- v-if="value && value.length > 0" -->
          <b-card
            v-if="value.length"
            class="align-items-center"
          >
            <div class="d-flex">
              <div class="qr-code-holder">
                <VueQRCodeComponent
                  id="qrcode"
                  ref="qrcode"
                  error-level="Q"
                  :text="value"
                  :size="size"
                  title=""
                />
              </div>
            </div>
          </b-card>
        </b-col>
        <meta-data
          :user-address="userAddresses"
          :hase-files="false"
          add-component-name="show-branche-address"
          edit-component-name="edit-branche-address"
        />
      </b-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { ref } from '@vue/composition-api'
import VueQRCodeComponent from 'vue-qrcode-component'
import branch from '../branch'
import MetaData from '@/components/MetaData.vue'

export default {
  components: { MetaData, VueQRCodeComponent },
  data() {
    return {
      size: 4000,
      value: '',
      loader: false,
      notFoundBranch: false,
    }
  },
  setup() {
    const { resolveActiveUser } = branch()
    const userAddresses = ref([])
    const viewData = ref({})

    return {
      viewData,
      resolveActiveUser,
      userAddresses,
    }
  },
  created() {
    this.getdata()
  },
  methods: {
    getdata() {
      this.loader = true
      axios.get(`branches/${this.$route.params.id}`).then(res => {
        this.viewData = res.data?.data
        this.userAddresses = res.data?.data.address
        const qrcode = {
          name: res.data?.data?.merchant_name ?? '',
          branchId: res.data?.data?.id ?? 0,
          merchant_id: Number(res.data?.data?.merchant_id) ?? 0,
          type: 'branch',
          user_image: res.data?.data?.merchant_logo ?? '',
          phone_number: res.data?.data?.merchant_phone ?? '',
        }
        this.value = JSON.stringify(qrcode)
      }).catch(error => {
        if ((error.response.status === 400 && error.response.data.message === 'This branch not belongs to the merchant owner.') || error.response.status === 404) {
          this.notFoundBranch = true
        }
      }).finally(() => {
        this.loader = false
      })
    },
  },
}
</script>

<style lang="scss">
/* CONTACTS */
.contact-box {
  padding: 20px;
  margin-bottom: 20px;
}
.contact-box > a {
  color: inherit;
}
.contact-box.center-version {
  padding: 0;
}
.contact-box.center-version > .top {
  display: block;
  padding: 5px;
  text-align: center;
}
.contact-box.center-version > .top img {
  width: 80px;
  height: 80px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.contact-box.center-version address {
  margin-bottom: 0;
}
.contact-box .contact-box-footer {
  border-top: 1px solid #e7eaec;
  padding: 15px 20px;
}
a{
    text-decoration:none !important;
}

.user-info ul {
  list-style: none;
  li {
    padding-top: 1rem;
  }
}
#qrcode img {
  width: 300px;
}
</style>
