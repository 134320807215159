<template>
  <div>
    <validation-observer
      ref="infoRules"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Merchant"
            label-for="type"
          >
            <v-select
              id="type"
              v-model="merchant_id"
              label="name"
              :filterable="false"
              :reduce="merchant => merchant"
              :options="merchants"
              @search="onSearch"
              @input="selectMerchant($event)"
            />
            <div v-if="validationErrors.merchant_id">
              <p class="text-danger">
                {{ validationErrors.merchant_id }}
              </p>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="userForm.name"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <b-form-group label="Client Cashback and Type">
            <b-input-group>
              <validation-provider
                #default="validationContext"
                name="Client Cashback"
                rules="required|regex:^([0-9]+)$"
              >
                <cleave
                  id="ZC"
                  v-model.number="userForm.client_cashback"
                  class="form-control"
                  :state="getValidationState(validationContext)"
                  :options="options.number"
                  placeholder="5.5"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
              <validation-provider
                #default="validationContext"
                name="Client Type"
                rules="required"
              >
                <b-input-group-append>
                  <b-form-select
                    v-model="userForm.client_cashback_type"
                    :options="amountpercentage"
                    :state="getValidationState(validationContext)"
                    right
                    value-field="value"
                    text-field="text"
                  >
                    <template #first>
                      <b-form-select-option
                        disabled
                        value=""
                      >
                        Select Type
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-input-group-append>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="ZeroCash Cashback and Type">
            <b-input-group>
              <validation-provider
                #default="validationContext"
                name="ZeroCash Cashback"
                rules="required|regex:^([0-9]+)$"
              >
                <cleave
                  id="ZC"
                  v-model.number="userForm.zerocash_cashback"
                  class="form-control"
                  :state="getValidationState(validationContext)"
                  :options="options.number"
                  placeholder="5.5"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
              <validation-provider
                #default="validationContext"
                name="ZeroCash Type"
                rules="required"
              >
                <b-input-group-append>
                  <b-form-select
                    v-model="userForm.zerocash_cashback_type"
                    :options="amountpercentage"
                    :state="getValidationState(validationContext)"
                    right
                    value-field="value"
                    text-field="text"
                  >
                    <template #first>
                      <b-form-select-option
                        disabled
                        value=""
                      >
                        Select Type
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-input-group-append>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Active"
            label-for="business_type"
          >
            <b-form-checkbox
              v-model="userForm.is_active"
              checked="true"
              class="custom-control-secondary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader"
            variant="primary"
            class="mr-1"
            @click="addMerchant()"
          >
            Save
          </b-button>
          <b-button
            variant="outline-danger"
            class="closeModal"
            @click="closeModal()"
          >
            Cancel
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import Cleave from 'vue-cleave-component'
import axios from 'axios'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import {
  required, regex,
} from '@validations'
import previewImage from '@/global-compositions/previewImage'
import branch from '../branch'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: { vSelect, Cleave },
  mixins: [previewImage],
  data() {
    return {
      required,
      regex,
      userForm: {},
      merchantType: [],
      merchants: [],
      id: this.$store.state.generalIds.id,
      loader: false,
      merchant_id: '',
      validationErrors: {
        name: '',
        merchant_id: '',
      },
      options: {
        number: {
          numeral: true,
          numeralPositiveOnly: true,
          noImmediatePrefix: true,
          rawValueTrimPrefix: true,
          numeralDecimalScale: 1,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
    }
  },
  setup() {
    const { getValidationState } = formValidation()

    const { userForm } = branch()

    const amountpercentage = [
      { text: 'Amount', value: 'amount' },
      { text: 'Percentage', value: 'percentage' },
    ]

    return {
      getValidationState,
      userForm,
      amountpercentage,
    }
  },
  mounted() {
    this.viewMerchant()
    // this.handleForm()
  },
  methods: {
    // handleForm() {
    //   const foo = {
    //     id: 1,
    //     fooos: 'gooo',
    //   }
    //   const zoo = Object.values(foo).length
    //   // console.log(Object.values(zoo))
    // },
    closeModal() {
      this.userForm = {
        merchant_id: '',
        name: '',
        is_active: '',
        client_cashback: '',
        zerocash_cashback: '',
      }
      this.$store.commit('generalIds/SET_ID', null)
      this.$bvModal.hide('merchant')
    },
    selectMerchant(value) {
      this.userForm.merchant_id = this.merchants.find(owner => owner.id === value.id).id
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        this.search(loading, search)
      }
    },
    search(loading, search) {
      axios.get(`get-merchants?search=${search}`).then(res => {
        if (search.length >= 2) {
          this.merchants = res.data.data
        }
        loading(false)
      })
    },
    addMerchant() {
      if (this.id) {
        if (this.userForm.is_active === true) {
          this.userForm.is_active = 1
        } else {
          this.userForm.is_active = 0
        }
        this.loader = true
        axios.put(`branches/${this.id}`, this.userForm).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$parent.$parent.$parent.$refs.merchantTable.getAllData()
            this.closeModal()
            this.$toasted.show('Added Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        if (this.userForm.is_active === true) {
          this.userForm.is_active = 1
        } else {
          this.userForm.is_active = 0
        }
        this.loader = true
        axios.post('branches', this.userForm).then(res => {
          this.$parent.$parent.$parent.$refs.merchantTable.getAllData()
          if (res.status === 200 || res.status === 201) {
            this.closeModal()
            this.$toasted.show('Added Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error) {
            this.validationErrors.merchant_id = error.response.data.errors.merchant_id.toString()
            this.validationErrors.name = error.response.data.errors.name.toString()
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },
    viewMerchant() {
      if (this.id) {
        axios.get(`branches/${this.id}`).then(res => {
          if (res.status === 200) {
            this.userForm = res.data?.data
            this.merchant_id = res.data?.data.merchant_name
          }
        })
      }
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
