<template>
  <div>
    <general-table
      ref="merchantTable"
      :api-url="APIURL"
      :add-type="addType"
      :type="type"
      :view-content="true"
      :columns="columns"
      guard="branches"
      :viw-component="viwComponent"
    />
    <div>
      <b-modal
        ref="view-modal"
        hide-footer
        centered
      >
        <view-branch />
      </b-modal>
      <div>
        <b-modal
          id="merchant"
          ref="genmodal"
          hide-footer
          centered
          size="lg"
          no-close-on-backdrop
          title="Add Branch"
        >
          <add-edit-branch />
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import AddEditBranch from './control-branch/AddEditBranch.vue'
import ViewBranch from './control-branch/ViewBranch.vue'

export default {
  components: { GeneralTable, AddEditBranch, ViewBranch },
  data() {
    return {
      APIURL: 'branches',
      addType: 'Add Branch',
      addComponentName: 'add-branche',
      editComponent: 'edit-branche',
      type: 'modal',
      viwComponent: 'show-branche',
      columns: [
        { key: 'select' },
        { key: 'id', sortable: true },
        { key: 'name', label: 'Name' },
        { key: 'merchant_name', label: 'Merchant Name' },
        { key: 'is_active', label: 'Active' },
        { key: 'actions' },
      ],
    }
  },
}
</script>

<style>

</style>
